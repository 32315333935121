import axios from 'axios';
import { supabase } from './supabaseClient';

const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_URL || 'http://localhost:8000',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosClient.interceptors.request.use(async (config) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (session?.access_token) {
      config.headers.Authorization = `Bearer ${session.access_token}`;
    }
    return config;
  } catch (error) {
    console.error('Error in request interceptor:', error);
    return Promise.reject(error);
  }
});

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      // Network error
      return Promise.reject({
        message: 'Network error - please check your connection'
      });
    }

    // Get error message from response
    const errorMessage = error.response.data?.message
      || error.response.data?.error
      || error.message
      || 'An unexpected error occurred';

    // Create error object with additional context
    const enhancedError = {
      message: errorMessage,
      status: error.response.status,
      data: error.response.data,
    };

    return Promise.reject(enhancedError);
  }
);

export default axiosClient;