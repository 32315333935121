import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Switch } from "@/components/ui/switch"

export default function ConfigDialog({ block, isOpen, onClose, api, integrations }) {
  const [config, setConfig] = useState(null)
  const [formData, setFormData] = useState({})
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const requiredIntegration = block.required_integration;
  const integration = requiredIntegration
    ? integrations?.find(i => i.provider === requiredIntegration && i.is_active)
    : null;

  const handleInputChange = (key, value) => {
    setFormData(prev => ({ ...prev, [key]: value }))
  }

  useEffect(() => {
    let isMounted = true

    if (isOpen && block) {
      setLoading(true)
      api.config.fetchData({
        url: `/api/call-blocks/user/${block.id}/config/`,
      })
        .then((data) => {
          if (isMounted) {
            setConfig(data.schema)
            // Initialize form with current settings
            const initialFormData = {}
            data.schema.fields.forEach((field) => {
              initialFormData[field.key] = data.current_settings?.[field.key] ?? field.default_value
            })
            setFormData(initialFormData)
          }
        })
        .catch((error) => {
          if (isMounted) {
            console.error('Failed to fetch config:', error)
            toast.error('Failed to load configuration. Please try again.')
          }
        })
        .finally(() => {
          if (isMounted) {
            setLoading(false)
          }
        })
    }

    return () => {
      isMounted = false
    }
  }, [isOpen, block?.id]) // Remove api from dependencies, use block.id instead of block


  // Reset state when dialog closes
  useEffect(() => {
    if (!isOpen) {
      setConfig(null)
      setFormData({})
      setLoading(true)
    }
  }, [isOpen])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setSaving(true)
      await api.update.fetchData({
        url: `/api/call-blocks/user/${block.id}/`,
        data: { settings: formData }
      })
      toast.success('Settings saved successfully')
      onClose()
    } catch (error) {
      console.error('Failed to save settings:', error)
      toast.error('Failed to save settings. Please try again.')
    } finally {
      setSaving(false)
    }
  }

  const handleIntegration = async (integrationType) => {
      try {
          const response = await api.connectIntegration.fetchData({
              url: `/api/integrations/${integrationType}/connect/`,
          });
          if (response?.authorization_url) {
              window.location.href = response.authorization_url;
          }
      } catch (error) {
          console.error('Failed to initiate integration:', error);
          toast.error('Failed to start integration process. Please try again.');
      }
  };

  if (loading) {
    return <div>Loading configuration...</div>
  }

  if (!config) {
    return null
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Configure {block.name}</DialogTitle>
        </DialogHeader>

        {/* Show integration alert if needed */}
        {requiredIntegration && !integration && (
          <BlockIntegrationAlert
            integration={integration}
            onConnect={() => handleIntegration(requiredIntegration)}
          />
        )}
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            {config.fields.map((field) => (
              <div key={field.key}>
                <Label htmlFor={field.key}>
                  {field.label}
                </Label>
                {field.type === 'integration' ? (
                  <Button
                    type="button"
                    onClick={() => handleIntegration(field.integration_type)}
                  >
                    Connect {field.label}
                  </Button>
                ) : field.type === 'toggle' ? (
                  <Switch
                    id={field.key}
                    checked={formData[field.key]}
                    onCheckedChange={(checked) => handleInputChange(field.key, checked)}
                  />
                ) : field.type === 'number' ? (
                  <Input
                    type="number"
                    id={field.key}
                    value={formData[field.key]}
                    onChange={(e) => handleInputChange(field.key, parseInt(e.target.value, 10))}
                    min={field.validation?.min_value}
                    max={field.validation?.max_value}
                    required={field.required}
                  />
                ) : (
                  <Input
                    type="text"
                    id={field.key}
                    value={formData[field.key]}
                    onChange={(e) => handleInputChange(field.key, e.target.value)}
                    maxLength={field.validation?.max_length}
                    pattern={field.validation?.pattern}
                    required={field.required}
                  />
                )}
                {field.description && (
                  <p className="text-sm text-muted-foreground mt-1">{field.description}</p>
                )}
              </div>
            ))}
          </div>
          <DialogFooter className="mt-6">
            <Button type="button" variant="outline" onClick={onClose} disabled={saving}>
              Cancel
            </Button>
            <Button type="submit" disabled={saving}>
              {saving ? 'Saving...' : 'Save'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}