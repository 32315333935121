import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../utils/supabaseClient';
import { toast } from 'react-toastify';
import PhoneVerification from './PhoneVerification';

export default function AuthCallback() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showPhoneVerification, setShowPhoneVerification] = useState(false);
  const [supabaseUid, setSupabaseUid] = useState(null);
  const [profile, setProfile] = useState({
    phone_number: '',
    phone_verified: false
  });

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();

        if (error) throw error;
        if (!session) {
          navigate('/signup');
          return;
        }

        // Register user in our backend
        const response = await fetch(
          `${import.meta.env.VITE_BACKEND_URL}/api/users/register/`,
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              supabase_uid: session.user.id,
              email: session.user.email,
              name: session.user.user_metadata.full_name,
              auth_provider: 'google',
              oauth_credentials: session.provider_token
            }),
          }
        );

        const data = await response.json();

        if (response.ok) {
          if (data.requires_phone_verification) {
            setSupabaseUid(session.user.id);
            setShowPhoneVerification(true);
            setLoading(false);
          } else {
            // User is already verified, navigate to dashboard/routine
            toast.success('Welcome back!');
            navigate('/dashboard/routine');
          }
        } else {
          throw new Error(data.error || 'Failed to register user');
        }

      } catch (error) {
        console.error('Auth callback error:', error);
        toast.error('Authentication failed. Please try again.');
        navigate('/signup');
      }
    };

    handleCallback();
  }, [navigate]);

  const handlePhoneChange = (e) => {
    console.log('Phone change:', e.target.name, e.target.value);
    setProfile(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleVerificationComplete = async (data) => {
    console.log('Verification complete:', data);
    try {
      // Update local state
      setProfile(prev => ({
        ...prev,
        phone_verified: true
      }));

      // Optionally fetch fresh user data
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/users/profile/`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch updated profile');
      }

      toast.success('Phone verified! Your account is ready.');
      navigate('/dashboard/routine');
    } catch (error) {
      console.error('Error completing verification:', error);
      toast.error('Error updating profile. Please try again.');
      // Still navigate since the phone is verified
      navigate('/dashboard/routine');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold mb-4">Setting up your account...</h2>
        </div>
      </div>
    );
  }

  if (showPhoneVerification) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-blue-50 via-blue-100 to-white px-4">
        <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md">
          <div className="text-center mb-6">
            <h1 className="text-2xl font-bold text-blue-600">One Last Step</h1>
            <h2 className="text-xl font-semibold text-gray-700 mt-2">Verify Your Phone</h2>
            <p className="text-gray-600 mt-2">
              We need your phone number to make your morning calls.
            </p>
          </div>
          <PhoneVerification
            phoneNumber={profile.phone_number}
            verified={profile.phone_verified}
            supabaseUid={supabaseUid}
            onPhoneChange={handlePhoneChange}
            onVerificationComplete={handleVerificationComplete}
          />
        </div>
      </div>
    );
  }

  return null;
}