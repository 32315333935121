// components/dashboard/sections/IntegrationsSection.jsx
import { useOutletContext } from 'react-router-dom';
import IntegrationsManager from '../IntegrationsManager';

const IntegrationsSection = () => {
  const context = useOutletContext();

  if (!context) {
    console.error('No context available in IntegrationsSection');
    return <div>Loading...</div>;
  }

  const { integrations, handleConnectIntegration, handleDisconnectIntegration } = context;

  if (!integrations) {
    console.error('No integrations data available');
    return <div>Loading integrations...</div>;
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">Connected Services</h2>
      <IntegrationsManager
        integrations={integrations}
        onConnect={handleConnectIntegration}
        onDisconnect={handleDisconnectIntegration}
      />
    </div>
  );
};

export default IntegrationsSection;