import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardContent } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import PhoneVerification from './PhoneVerification';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

const TimezoneSelect = ({ value, onChange }) => {
  const timezones = [
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Phoenix',
    'Pacific/Honolulu',
    'Europe/London',
    'Europe/Paris',
    'Asia/Tokyo',
    'Asia/Shanghai',
    'Australia/Sydney',
  ];

  const formatTimezone = (tz) => {
    try {
      const now = new Date();
      const formattedTime = now.toLocaleTimeString('en-US', {
        timeZone: tz,
        timeZoneName: 'short'
      });
      return `${tz.replace('_', ' ')} (${formattedTime.split(' ').pop()})`;
    } catch (e) {
      console.error('Error formatting timezone:', e);
      return tz;
    }
  };

  return (
    <Select value={value} onValueChange={(value) => onChange({ target: { name: 'timezone', value } })}>
      <SelectTrigger>
        <SelectValue placeholder="Select a timezone">
          {value ? formatTimezone(value) : 'Select a timezone'}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {timezones.map((tz) => (
          <SelectItem key={tz} value={tz}>
            {formatTimezone(tz)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};


const ProfileSection = () => {
  const {
    profile,
    handleProfileChange,
    api
  } = useOutletContext();

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Create a proper event handler for phone changes
  const handlePhoneChange = (e) => {
    console.log('Phone change:', e.target.name, e.target.value);
    if (typeof handleProfileChange === 'function') {
      handleProfileChange({
        target: {
          name: e.target.name,
          value: e.target.value
        }
      });
    } else {
      console.error('handleProfileChange is not a function:', handleProfileChange);
    }
  };

  const handlePhoneVerificationComplete = async (data) => {
    console.log('Verification complete:', data);

    try {
      // Update verification status
      handlePhoneChange({
        target: {
          name: 'phone_verified',
          value: true
        }
      });

      // Optionally refresh the entire profile
      if (api?.getProfile?.fetchData) {
        const response = await api.getProfile.fetchData();
        console.log('Fresh profile data:', response);

        // Update all relevant fields from the response
        Object.entries(response).forEach(([key, value]) => {
          handlePhoneChange({
            target: {
              name: key,
              value: value
            }
          });
        });
      }

      toast.success('Phone verification complete!');
    } catch (error) {
      console.error('Error updating profile after verification:', error);
      toast.error('Error updating profile. Please refresh the page.');
    }
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      console.log('Submitting profile:', profile);
      const response = await api.updateProfile.fetchData({
        data: profile
      });

      console.log('Profile update response:', response);

      if (response.requires_phone_verification) {
        toast.info('Phone verification required');
        return;
      }

      toast.success('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error(error.message || 'Failed to update profile');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">Profile Settings</h2>

      <Card>
        <CardContent>
          <form onSubmit={handleProfileSubmit} className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="first_name">First Name</Label>
                <Input
                  id="first_name"
                  name="first_name"
                  value={profile.first_name || ''}
                  onChange={handleProfileChange}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="last_name">Last Name</Label>
                <Input
                  id="last_name"
                  name="last_name"
                  value={profile.last_name || ''}
                  onChange={handleProfileChange}
                />
              </div>
            </div>

            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                name="email"
                type="email"
                value={profile.email || ''}
                disabled
                className="bg-gray-50"
              />
              <p className="text-sm text-gray-500">Email is managed through your account settings</p>
            </div>

            <PhoneVerification
              phoneNumber={profile.phone_number}
              verified={profile.phone_verified}
              supabaseUid={profile.supabase_uid}
              onPhoneChange={handlePhoneChange}
              onVerificationComplete={handlePhoneVerificationComplete}
            />

            <div className="space-y-2">
              <Label htmlFor="timezone">Timezone</Label>
              <TimezoneSelect
                value={profile.timezone}
                onChange={handleProfileChange}
              />
            </div>

            <Button
              type="submit"
              className="w-full"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving...' : 'Save Changes'}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default ProfileSection;