// App.jsx
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthProvider';
import { ToastContainer } from 'react-toastify';
import AdminDashboard from './components/dashboard/AdminDashboard';
import ProfileSection from './components/dashboard/sections/ProfileSection';
import IntegrationsSection from './components/dashboard/sections/IntegrationsSection';
import RoutineSection from './components/dashboard/sections/RoutineSection';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import AuthCallback from './components/auth/AuthCallback';
import VerificationPending from './components/auth/VerificationPending';
import LandingPage from './components/LandingPage';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/verification-pending" element={<VerificationPending />} />

          {/* Dashboard Routes */}
          <Route path="/dashboard" element={<AdminDashboard />}>
            <Route path="routine" element={<RoutineSection />} />
            <Route path="profile" element={<ProfileSection />} />
            <Route path="integrations" element={<IntegrationsSection />} />
            <Route index element={<Navigate to="/dashboard/routine" replace />} />
          </Route>

          {/* Catch all */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <ToastContainer />
      </AuthProvider>
    </Router>
  );
}

export default App;