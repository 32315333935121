import { useState, useEffect } from 'react';
import { AlertCircle, Phone, Check } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { toast } from 'react-toastify';

const PhoneVerification = ({
  phoneNumber,
  verified,
  supabaseUid,
  onPhoneChange,
  onVerificationComplete
}) => {
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [showCallAlert, setShowCallAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localPhoneNumber, setLocalPhoneNumber] = useState(phoneNumber || '');

  useEffect(() => {
    setLocalPhoneNumber(phoneNumber || '');
  }, [phoneNumber]);

  const handleStartVerification = async () => {
    if (!localPhoneNumber || !supabaseUid) {
      toast.error('Phone number is required');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/users/send-phone-verification/`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            phone_number: localPhoneNumber,
            supabase_uid: supabaseUid
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to initiate verification');
      }

      setIsVerifying(true);
      setShowCallAlert(true);
      toast.success('Verification call initiated!');
    } catch (error) {
      console.error('Error initiating verification:', error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    if (!verificationCode || !supabaseUid) {
      toast.error('Verification code is required');
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/users/verify-phone/`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            code: verificationCode,
            supabase_uid: supabaseUid
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Invalid verification code');
      }

      setIsVerifying(false);
      setShowCallAlert(false);

      // Update both the phone number and verification status in parent
      onPhoneChange({
        target: {
          name: 'phone_number',
          value: localPhoneNumber
        }
      });
      onPhoneChange({
        target: {
          name: 'phone_verified',
          value: true
        }
      });

      onVerificationComplete(data);
      toast.success(data.message || 'Phone number verified successfully!');
    } catch (error) {
      console.error('Error verifying code:', error);
      toast.error(error.message);
      setVerificationCode(''); // Clear invalid code
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const newValue = e.target.value;
    setLocalPhoneNumber(newValue);
    // Only propagate to parent component once verified
    if (verified) {
      onPhoneChange({
        target: {
          name: 'phone_number',
          value: newValue
        }
      });
    }
  };

  const handleCancel = () => {
    setIsVerifying(false);
    setShowCallAlert(false);
    setVerificationCode('');
    // Reset to last verified phone number
    setLocalPhoneNumber(phoneNumber || '');
  };

  // Remove call alert after 30 seconds
  useEffect(() => {
    let timeout;
    if (showCallAlert) {
      timeout = setTimeout(() => setShowCallAlert(false), 30000);
    }
    return () => clearTimeout(timeout);
  }, [showCallAlert]);

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="phone_number">Phone Number</Label>
        <div className="flex items-center gap-2">
          <Input
            id="phone_number"
            name="phone_number"
            type="tel"
            placeholder="+1 (555) 555-5555"
            value={localPhoneNumber}
            onChange={handlePhoneNumberChange}
            className="flex-1"
          />
          <div className="flex items-center gap-2 text-sm">
            {verified ? (
              <div className="flex items-center text-green-600 dark:text-green-500">
                <Check className="h-4 w-4 mr-1" />
                Verified
              </div>
            ) : (
              <div className="flex items-center text-yellow-600 dark:text-yellow-500">
                <AlertCircle className="h-4 w-4 mr-1" />
                Not verified
                {!isVerifying && (
                  <Button
                    variant="link"
                    className="text-sm px-2 h-6"
                    onClick={handleStartVerification}
                    disabled={loading || !localPhoneNumber}
                  >
                    Verify now
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {isVerifying && (
        <form onSubmit={handleVerifyCode} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="verificationCode">Enter Verification Code</Label>
            <Input
              id="verificationCode"
              type="text"
              placeholder="Enter 6-digit code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              maxLength={6}
              className="text-center text-xl tracking-wider"
              required
              disabled={loading}
            />
          </div>
          <div className="flex justify-end gap-2">
            <Button
              type="button"
              variant="ghost"
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={loading || !verificationCode}
            >
              {loading ? 'Verifying...' : 'Verify Code'}
            </Button>
          </div>
        </form>
      )}

      {showCallAlert && (
        <Alert className="fixed bottom-4 right-4 w-96 bg-blue-50 border-blue-200 animate-slide-in">
          <Phone className="h-4 w-4 text-blue-600" />
          <AlertTitle>Incoming Verification Call</AlertTitle>
          <AlertDescription>
            Calling {localPhoneNumber}... Listen for your verification code.
          </AlertDescription>
        </Alert>
      )}
    </div>
  );
};

export default PhoneVerification;