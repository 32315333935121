import React, { useState } from 'react';
import { Phone } from 'lucide-react';
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "@/components/ui/dialog";
import { toast } from 'react-toastify';
import useAxios from '@/hooks/useAxios';

const TestCallButton = ({ phoneNumber }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const testCall = useAxios({
    url: '/api/call-blocks/test-call/',
    method: 'POST',
  });

  const formatPhoneNumber = (phone) => {
    return phone || 'No phone number set';
  };

  const handleTestCall = async () => {
    if (!phoneNumber) {
      toast.error('Please set up your phone number in your profile first.');
      setDialogOpen(false);
      return;
    }

    setIsLoading(true);
    try {
      await testCall.fetchData();
      toast.success('Test call initiated! You should receive a call shortly.');
      setDialogOpen(false);
    } catch (error) {
      toast.error('Failed to initiate test call. Please try again.');
      console.error('Test call error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" size="lg" className="gap-2">
          <Phone className="h-4 w-4" />
          Make Test Call
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Make a Test Call</DialogTitle>
          <DialogDescription>
            This will initiate an immediate test call to your registered phone number:{' '}
            <span className="font-medium">{formatPhoneNumber(phoneNumber)}</span>
          </DialogDescription>
        </DialogHeader>
        <div className="flex justify-end gap-3">
          <DialogClose asChild>
            <Button variant="outline" disabled={isLoading}>Cancel</Button>
          </DialogClose>
          <Button
            onClick={handleTestCall}
            disabled={isLoading || !phoneNumber}
            className="gap-2"
          >
            {isLoading ? (
              <>
                <div className="h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent" />
                Initiating...
              </>
            ) : (
              <>
                <Phone className="h-4 w-4" />
                Start Call
              </>
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TestCallButton;