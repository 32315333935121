import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import axiosClient from '../utils/axiosClient';

export default function useAxios(options = {}) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async (params = {}) => {
    try {
      setLoading(true);
      setError(null);

      console.log('Making request with:', { ...options, ...params });

      const response = await axiosClient.request({
        ...options,
        ...params,
      });

      setData(response.data);
      return response.data;
    } catch (err) {
      console.error('Axios error:', {
        message: err.message,
        response: err.response?.data,
        status: err.response?.status,
        config: err.config
      });

      const errorMessage = err.response?.data?.message
        || err.response?.data?.error
        || err.message
        || 'An error occurred';

      setError(errorMessage);

      if (options.showToast !== false) {
        toast.error(errorMessage);
      }

      throw errorMessage;
    } finally {
      setLoading(false);
    }
  }, [options]);

  return { data, error, loading, fetchData };
}