import { useState, useEffect } from 'react'
import VoiceSelector from './VoiceSelector'

import { Clock, Calendar } from 'lucide-react'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Switch } from "@/components/ui/switch"
import { Button } from "@/components/ui/button"

const ScheduleManager = ({ initialSchedule, onSave }) => {
  const [schedule, setSchedule] = useState({
    enabled: true,
    call_time: '08:00',
    weekdays: ['1', '2', '3', '4', '5'],
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    voice_id: 'onwK4e9ZLuTAKqWW03F9',
  });

  useEffect(() => {
    if (initialSchedule) {
      setSchedule({
        ...initialSchedule,
        weekdays: initialSchedule.weekdays.split(','),
      });
    }
  }, [initialSchedule]);

  const weekdayLabels = {
    '1': 'Monday',
    '2': 'Tuesday',
    '3': 'Wednesday',
    '4': 'Thursday',
    '5': 'Friday',
    '6': 'Saturday',
    '7': 'Sunday'
  };

  const handleTimeChange = (e) => {
    setSchedule(prev => ({
      ...prev,
      call_time: e.target.value
    }));
  };

  const handleWeekdayToggle = (day) => {
    setSchedule(prev => {
      const newWeekdays = prev.weekdays.includes(day)
        ? prev.weekdays.filter(d => d !== day)
        : [...prev.weekdays, day].sort();

      return {
        ...prev,
        weekdays: newWeekdays
      };
    });
  };

  const handleSave = () => {
    onSave({
      ...schedule,
      weekdays: schedule.weekdays.join(',')
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Clock className="h-5 w-5" />
          Wake-up Schedule
        </CardTitle>
        <CardDescription>Configure your daily wake-up call schedule</CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="flex items-center justify-between">
          <Label htmlFor="schedule-enabled">Enable Wake-up Calls</Label>
          <Switch
            id="schedule-enabled"
            checked={schedule.enabled}
            onCheckedChange={(checked) => setSchedule(prev => ({ ...prev, enabled: checked }))}
          />
        </div>

        <div className="space-y-2">
          <Label htmlFor="wake-time">Wake-up Time</Label>
          <div className="flex items-center gap-2">
            <Clock className="h-4 w-4 text-muted-foreground" />
            <Input
              type="time"
              id="wake-time"
              value={schedule.call_time}
              onChange={handleTimeChange}
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label className="flex items-center gap-2">
            <Calendar className="h-4 w-4" />
            Active Days
          </Label>
          <div className="grid grid-cols-7 gap-2">
            {Object.entries(weekdayLabels).map(([day, label]) => (
              <Button
                key={day}
                onClick={() => handleWeekdayToggle(day)}
                variant={schedule.weekdays.includes(day) ? "default" : "outline"}
                className="p-2 text-sm"
              >
                {label.slice(0, 3)}
              </Button>
            ))}
          </div>
        </div>

        <VoiceSelector
          voiceId={schedule.voice_id}
          onVoiceChange={(newVoiceId) =>
            setSchedule(prev => ({ ...prev, voice_id: newVoiceId }))
          }
        />

        <Button onClick={handleSave} className="w-full">
          Save Schedule
        </Button>
      </CardContent>
    </Card>
  );
};

export default ScheduleManager;