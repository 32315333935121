// components/dashboard/sections/RoutineSection.jsx
import { useOutletContext } from 'react-router-dom';
import BlockCard from '../BlockCard';
import RoutineManager from '../RoutineManager';
import ScheduleManager from '../ScheduleManager';
import TestCallButton from '../TestCallButton';

const RoutineSection = () => {
  const context = useOutletContext();

  if (!context) {
    console.error('No context available in RoutineSection');
    return <div>Loading...</div>;
  }

  const {
    availableBlocks,
    userBlocks,
    setUserBlocks,
    integrations,
    handleAddBlock,
    handleConnectIntegration,
    schedule,
    handleScheduleSave,
    handleTestCall,
    profile, // we need this for the phone number
    api
  } = context;

  if (!availableBlocks || !userBlocks) {
    console.error('Required data not available');
    return <div>Loading routine data...</div>;
  }

  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-bold">My Morning Routine</h2>
      <TestCallButton
        onTestCall={handleTestCall}
        phoneNumber={profile?.phone_number}
      />
      <div>
        <h3 className="text-xl font-semibold mb-6">Available Blocks</h3>
        <div className="grid gap-4 md:grid-cols-2 xl:grid-cols-3">
          {availableBlocks.map((block) => (
            <BlockCard
              key={block.id}
              block={block}
              onAdd={handleAddBlock}
              integrations={integrations}
              onConnectIntegration={handleConnectIntegration}
            />
          ))}
        </div>
      </div>

      <RoutineManager
        userBlocks={userBlocks}
        setUserBlocks={setUserBlocks}
        api={api}
      />

      <ScheduleManager
        initialSchedule={schedule}
        onSave={handleScheduleSave}
      />
    </div>
  );
};

export default RoutineSection;