// src/components/VerificationPending.jsx
import React from 'react';
import { Link } from 'react-router-dom';

const VerificationPending = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-blue-50 via-blue-100 to-white px-4">
      <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md">
        <div className="text-center mb-6">
          <h1 className="text-2xl font-bold text-blue-600">DayRiser</h1>
          <h2 className="text-xl font-semibold text-gray-700 mt-2">Verify Your Email</h2>
        </div>

        <div className="space-y-6">
          <p className="text-gray-600 text-center">
            We've sent you an email with a confirmation link. Please check your inbox and click the link to verify your account.
          </p>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">
                Didn't receive the email?
              </span>
            </div>
          </div>

          <p className="text-sm text-gray-500 text-center">
            Check your spam folder or return to sign in
          </p>

          <Link
            to="/signin"
            className="w-full block py-2 px-4 rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-all duration-200 text-center"
          >
            Return to Sign In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VerificationPending;