import React from 'react'
import { Link } from 'react-router-dom'
import { Sun, Cloud, Calendar, Star, Check, ChevronRight, Lightbulb } from 'lucide-react'

export default function LandingPage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 via-blue-100 to-white text-gray-900">
      <header className="sticky top-0 bg-white/80 backdrop-blur-md z-50 border-b border-gray-200">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link to="/" className="text-2xl font-bold text-blue-600 flex items-center">
            <Sun className="w-8 h-8 mr-2 text-blue-600" />
            DayRiser
          </Link>
          <nav className="space-x-6 text-sm">
            <Link to="#features" className="hover:text-blue-600 transition-colors">Features</Link>
            <Link to="#how-it-works" className="hover:text-blue-600 transition-colors">How it Works</Link>
            <Link to="#pricing" className="hover:text-blue-600 transition-colors">Pricing</Link>
            <Link to="/signin" className="text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-md transition-colors">
              Sign In
            </Link>
          </nav>
        </div>
      </header>

      <main>
        {/* Hero Section */}
        <section className="relative overflow-hidden py-24 md:py-32">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 mb-10 md:mb-0">
                <h1 className="text-4xl md:text-5xl font-bold leading-tight mb-6 text-gray-900">
                  Wake up inspired with <span className="text-blue-600">DayRiser</span>
                </h1>
                <p className="text-xl mb-8 text-gray-700">Start your day with personalized wake-up calls, weather
                  updates, and schedule insights - all in one app.</p>
                <button
                  className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-md flex items-center text-lg font-semibold transition-colors">
                  Start your free trial
                  <ChevronRight className="ml-2 h-5 w-5"/>
                </button>
              </div>
              <div className="md:w-1/2 relative">
                <img
                  src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/dayriser-app-mockup-4K9Hq0MtO8k9H76666666XfXX1bBBBB.png"
                  alt="DayRiser App Interface showing a personalized wake-up screen"
                  className="rounded-lg shadow-xl"
                />
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section id="features" className="py-24 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-16 text-gray-900">Intelligent Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
              {[
                {
                  Icon: Cloud,
                  title: "Smart Weather",
                  description: "Get hyper-local weather forecasts to start your day prepared."
                },
                {
                  Icon: Calendar,
                  title: "Calendar Sync",
                  description: "Preview your day's schedule right when you wake up."
                },
                {
                  Icon: Lightbulb,
                  title: "Personalized Insights",
                  description: "Receive tailored motivation and productivity tips."
                },
              ].map((feature, index) => (
                <div key={index} className="text-center">
                  <feature.Icon className="w-12 h-12 mb-6 mx-auto text-blue-600"/>
                  <h3 className="text-xl font-semibold mb-3 text-gray-900">{feature.title}</h3>
                  <p className="text-gray-700">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* How it Works Section */}
        <section id="how-it-works" className="py-24 bg-blue-50">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-16 text-gray-900">How DayRiser Works</h2>
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="md:w-1/2 mb-10 md:mb-0">
                <img
                  src="/placeholder.svg?height=600&width=600"
                  alt="DayRiser App Demo"
                  className="rounded-lg shadow-xl"
                />
              </div>
              <div className="md:w-1/2 md:pl-12">
                <ol className="space-y-8">
                  {[
                    {title: "Set Up Your Profile", description: "Create your account and customize your preferences."},
                    {title: "Choose Your Wake-Up Time", description: "Select when you want to start your day."},
                    {
                      title: "Enjoy Personalized Wake-Up Calls",
                      description: "Receive intelligent, tailored wake-up calls every morning."
                    },
                  ].map((step, index) => (
                    <li key={index} className="flex items-start">
                      <span
                        className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-600 text-white text-sm font-bold mr-4">{index + 1}</span>
                      <div>
                        <h3 className="text-xl font-semibold mb-2 text-gray-900">{step.title}</h3>
                        <p className="text-gray-700">{step.description}</p>
                      </div>
                    </li>
                  ))}
                </ol>
              </div>
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section id="pricing" className="py-24 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-16 text-gray-900">Simple Pricing</h2>
            <div className="flex flex-col md:flex-row justify-center items-center md:items-stretch gap-8">
              {[
                {
                  plan: "Basic",
                  price: "Free",
                  period: "forever",
                  features: [
                    "Daily wake-up call",
                    "Basic customization",
                    "Weather updates"
                  ]
                },
                {
                  plan: "Premium",
                  price: "$49",
                  period: "per year",
                  features: [
                    "Everything in Basic",
                    "Fully customizable wake-up calls",
                    "Calendar integration",
                    "Detailed weather forecasts",
                    "Daily horoscope",
                    "Word of the day",
                    "Priority support"
                  ]
                },
              ].map((plan, index) => (
                <div key={index}
                     className={`w-full md:w-96 bg-white rounded-lg ${index === 1 ? 'border-2 border-blue-600 shadow-xl' : 'border border-gray-200 shadow-lg'} p-8`}>
                  <h3 className="text-2xl font-bold mb-4 text-gray-900">{plan.plan}</h3>
                  <p className="text-4xl font-bold mb-2 text-gray-900">{plan.price}</p>
                  <p className="text-lg text-gray-700 mb-6">{plan.period}</p>
                  <ul className="text-gray-700 mb-8 space-y-2">
                    {plan.features.map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex items-center">
                        <Check className="h-5 w-5 text-green-500 mr-2"/>
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <button
                    className={`w-full px-6 py-3 rounded-md ${index === 1 ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-gray-100 text-gray-900 hover:bg-gray-200'} transition-colors`}>
                    {index === 0 ? 'Start for Free' : 'Upgrade to Premium'}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-24 bg-blue-600 text-white">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl font-bold mb-8">Ready to rise and shine?</h2>
            <p className="text-xl mb-8">Start your journey to better mornings today with DayRiser.</p>
            <button
              className="bg-white text-blue-600 px-6 py-3 rounded-md shadow-lg hover:bg-gray-100 transition-colors text-lg font-semibold">
              Get Started for Free
            </button>
          </div>
        </section>
      </main>

      <footer className="bg-white py-12 border-t border-gray-200">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <Link to="/" className="text-2xl font-bold text-blue-600 flex items-center">
              <Sun className="w-6 h-6 mr-2 text-blue-600"/>
              DayRiser
            </Link>
            <nav className="flex flex-wrap justify-center gap-6 text-sm text-gray-700 mt-4 md:mt-0">
              <Link to="#features" className="hover:text-blue-600 transition-colors">Features</Link>
              <Link to="#how-it-works" className="hover:text-blue-600 transition-colors">How it Works</Link>
              <Link to="#pricing" className="hover:text-blue-600 transition-colors">Pricing</Link>
              <Link to="#" className="hover:text-blue-600 transition-colors">Privacy Policy</Link>
              <Link to="#" className="hover:text-blue-600 transition-colors">Terms of Service</Link>
            </nav>
          </div>
          <div className="mt-8 text-center text-sm text-gray-700">
            <p>&copy; {new Date().getFullYear()} DayRiser. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  )
}