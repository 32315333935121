// dashboard/IntegrationsManager.jsx
import React from 'react';
import { Calendar, AlertCircle, Check, X } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";

// New component to manage integrations
const IntegrationsManager = ({ integrations, onConnect, onDisconnect }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Connected Services</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <IntegrationCard
          name="Google Calendar"
          provider="google_calendar"
          icon={<Calendar className="h-5 w-5" />}
          integration={integrations?.find(i => i.provider === 'google_calendar')}
          onConnect={() => onConnect('google_calendar')}
          onDisconnect={() => onDisconnect('google_calendar')}
        />
      </CardContent>
    </Card>
  );
};

const IntegrationCard = ({ name, provider, icon, integration, onConnect, onDisconnect }) => {
  const isConnected = integration?.is_active;
  const lastSync = integration?.last_sync;

  return (
    <div className="flex items-center justify-between p-4 bg-muted rounded-lg">
      <div className="flex items-center space-x-4">
        {icon}
        <div>
          <h3 className="font-medium">{name}</h3>
          {isConnected && lastSync && (
            <p className="text-sm text-muted-foreground">
              Last synced: {new Date(lastSync).toLocaleString()}
            </p>
          )}
        </div>
      </div>
      <Button
        variant={isConnected ? "outline" : "default"}
        onClick={isConnected ? onDisconnect : onConnect}
        className="flex items-center space-x-2"
      >
        {isConnected ? (
          <>
            <X className="h-4 w-4" />
            <span>Disconnect</span>
          </>
        ) : (
          <>
            <Check className="h-4 w-4" />
            <span>Connect</span>
          </>
        )}
      </Button>
    </div>
  );
};

// Update ConfigDialog to separate integration requirements
const BlockIntegrationAlert = ({ integration, onConnect }) => {
  if (!integration) {
    return (
      <Alert variant="destructive" className="mb-4">
        <AlertCircle className="h-4 w-4" />
        <AlertDescription>
          This block requires Google Calendar integration.{' '}
          <Button
            variant="link"
            className="p-0 h-auto font-semibold"
            onClick={onConnect}
          >
            Connect now
          </Button>
        </AlertDescription>
      </Alert>
    );
  }
  return null;
};

export default IntegrationsManager;