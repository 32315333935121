import { useState } from 'react';
import { Volume2, Play, Square } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { toast } from 'react-toastify';
import useAxios from '@/hooks/useAxios';

const PRESET_VOICES = [
  { id: "onwK4e9ZLuTAKqWW03F9", name: "Rachel", description: "Warm and professional female voice" },
  { id: "21m00Tcm4TlvDq8ikWAM", name: "Adam", description: "Clear and authoritative male voice" },
  { id: "AZnzlk1XvdvUeBnXmlld", name: "Domi", description: "Friendly and energetic female voice" },
  { id: "MF3mGyEYCl7XYWbV9V6O", name: "Elli", description: "Soft and gentle female voice" },
];

const SAMPLE_TEXT = "Good morning! Time to start your day with energy and purpose.";

export default function VoiceSelector({ voiceId, onVoiceChange, api }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(null);
  const [customInput, setCustomInput] = useState('');

  const previewApi = useAxios({
    url: '/api/voice/preview/',
    method: 'POST',
    responseType: 'blob'
  });

  // Check if current voiceId is a preset or custom
  const isPresetVoice = PRESET_VOICES.some(voice => voice.id === voiceId);
  const customVoiceId = isPresetVoice ? '' : voiceId;

  const handleVoicePreview = async (previewVoiceId) => {
    if (isPlaying) {
      audio?.pause();
      setIsPlaying(false);
      return;
    }

    try {
      const response = await previewApi.fetchData({
        data: {
          voice_id: previewVoiceId,
          text: SAMPLE_TEXT
        }
      });

      if (!response) {
        throw new Error('Failed to generate preview');
      }

      const audioUrl = URL.createObjectURL(response);
      const audioObj = new Audio(audioUrl);

      audioObj.onended = () => {
        setIsPlaying(false);
        URL.revokeObjectURL(audioUrl);
      };

      setAudio(audioObj);
      audioObj.play();
      setIsPlaying(true);
    } catch (error) {
      console.error('Preview failed:', error);
      toast.error('Failed to generate voice preview');
      setIsPlaying(false);
    }
  };

  const handleCustomVoiceSubmit = async (e) => {
    e.preventDefault();
    if (!customInput.trim()) return;

    try {
      // Attempt to preview the voice first to validate it
      await previewApi.fetchData({
        data: {
          voice_id: customInput.trim(),
          text: SAMPLE_TEXT
        }
      });

      // If preview succeeds, update the voice
      onVoiceChange(customInput.trim());
      setCustomInput(''); // Clear input after successful submission
      toast.success('Custom voice set successfully');
    } catch (error) {
      console.error('Invalid voice ID:', error);
      toast.error('Invalid voice ID. Please check and try again.');
    }
  };

  const handleRadioChange = (value) => {
    if (value !== "custom") {
      onVoiceChange(value);
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Volume2 className="h-5 w-5" />
          Wake-up Voice
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <RadioGroup
          value={isPresetVoice ? voiceId : "custom"}
          onValueChange={handleRadioChange}
          className="space-y-4"
        >
          {PRESET_VOICES.map((voice) => (
            <div key={voice.id}
                 className="flex items-center justify-between space-x-2 p-3 border rounded-lg hover:bg-accent"
                 data-selected={voice.id === voiceId}>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value={voice.id} id={voice.id} />
                <div>
                  <Label htmlFor={voice.id} className="font-medium">
                    {voice.name}
                  </Label>
                  <p className="text-sm text-muted-foreground">
                    {voice.description}
                  </p>
                </div>
              </div>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleVoicePreview(voice.id)}
                type="button"
                disabled={previewApi.loading}
              >
                {isPlaying && voiceId === voice.id ? (
                  <Square className="h-4 w-4" />
                ) : (
                  <Play className="h-4 w-4" />
                )}
              </Button>
            </div>
          ))}

          <div className="flex items-center justify-between space-x-2 p-3 border rounded-lg hover:bg-accent">
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="custom" id="custom" />
              <div className="flex-1">
                <Label htmlFor="custom" className="font-medium">
                  Custom Voice
                </Label>
                <p className="text-sm text-muted-foreground">
                  Use your own ElevenLabs voice ID
                </p>
              </div>
            </div>
          </div>
        </RadioGroup>

        {/* Show current custom voice ID if one is set */}
        {!isPresetVoice && voiceId && (
          <div className="text-sm text-muted-foreground pl-6">
            Current custom voice ID: {voiceId}
          </div>
        )}

        <form onSubmit={handleCustomVoiceSubmit} className="flex space-x-2">
          <Input
            value={customInput}
            onChange={(e) => setCustomInput(e.target.value)}
            placeholder="Enter ElevenLabs Voice ID"
            disabled={previewApi.loading}
          />
          <Button
            type="button"
            variant="ghost"
            size="icon"
            onClick={() => customInput && handleVoicePreview(customInput)}
            disabled={!customInput || previewApi.loading}
          >
            {previewApi.loading ? (
              <div className="h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent" />
            ) : (
              <Play className="h-4 w-4" />
            )}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}