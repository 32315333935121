import React from 'react'
import { useState } from 'react'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { GripVertical, Settings, X } from 'lucide-react'
import { toast } from 'react-toastify'

import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import ConfigDialog from './ConfigDialog'

const SortableItem = ({ block, onRemove, onConfigure }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: block.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`flex items-center justify-between p-4 bg-card rounded-lg transition-all duration-200 hover:bg-accent hover:text-accent-foreground`}
    >
      <div className="flex items-center space-x-3">
        <div {...attributes} {...listeners} className="cursor-move">
          <GripVertical className="h-5 w-5 text-muted-foreground" />
        </div>
        <span className="text-2xl">{block.icon || '🔲'}</span>
        <div>
          <h3 className="font-medium">{block.name}</h3>
          <p className="text-sm text-muted-foreground">{block.description}</p>
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <Button variant="ghost" size="icon" onClick={() => onConfigure(block)}>
          <Settings className="h-4 w-4" />
          <span className="sr-only">Configure {block.name}</span>
        </Button>
        <Button variant="ghost" size="icon" onClick={() => onRemove(block.id)}>
          <X className="h-4 w-4" />
          <span className="sr-only">Remove {block.name}</span>
        </Button>
      </div>
    </div>
  )
}

export default function RoutineManager({ userBlocks, setUserBlocks, api }) {
  const [configBlock, setConfigBlock] = useState(null)

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const handleDragEnd = async (event) => {
    const { active, over } = event

    if (active.id !== over.id) {
      setUserBlocks((blocks) => {
        const oldIndex = blocks.findIndex((block) => block.id === active.id)
        const newIndex = blocks.findIndex((block) => block.id === over.id)
        const newBlocks = arrayMove(blocks, oldIndex, newIndex)

        // Prepare the data for the single PATCH request
        const updatedOrders = newBlocks.map((block, index) => ({
          id: block.id,
          order: index,
        }))

        // Send a single PATCH request to update all orders
        api.update.fetchData({
          url: '/api/call-blocks/user/',
          method: 'PATCH',
          data: updatedOrders,
        })
          .then(() => {
            toast.success('Routine order updated successfully')
          })
          .catch((error) => {
            console.error('Failed to update block order:', error)
            toast.error('Failed to update block order. Please try again.')
          })

        return newBlocks
      })
    }
  }

  const handleRemoveBlock = async (blockId) => {
    try {
      await api.delete.fetchData({
        url: `/api/call-blocks/user/${blockId}/`,
      })

      setUserBlocks((prev) => prev.filter((block) => block.id !== blockId))
      toast.success('Block removed from routine')
    } catch (error) {
      console.error('Failed to remove block:', error)
      toast.error('Failed to remove block. Please try again.')
    }
  }

  const handleConfigureBlock = (block) => {
    setConfigBlock(block)
  }

  const handleCloseConfig = () => {
    setConfigBlock(null)
  }

  const handleSaveConfig = async (blockId, newSettings) => {
    try {
      await api.update.fetchData({
        url: `/api/call-blocks/user/${blockId}/`,
        method: 'PATCH',
        data: { settings: newSettings },
      })

      setUserBlocks((prevBlocks) =>
        prevBlocks.map((block) =>
          block.id === blockId ? { ...block, settings: newSettings } : block
        )
      )

      toast.success('Block settings updated successfully')
      setConfigBlock(null)
    } catch (error) {
      console.error('Failed to update block settings:', error)
      toast.error('Failed to update block settings. Please try again.')
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Your Routine</CardTitle>
      </CardHeader>
      <CardContent>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={userBlocks.map(block => block.id)}
            strategy={verticalListSortingStrategy}
          >
            <div className="space-y-2">
              {userBlocks.map((block) => (
                <SortableItem
                  key={block.id}
                  block={block}
                  onRemove={handleRemoveBlock}
                  onConfigure={handleConfigureBlock}
                />
              ))}
            </div>
          </SortableContext>
        </DndContext>
      </CardContent>
      {configBlock && (
        <ConfigDialog
          block={configBlock}
          isOpen={!!configBlock}
          onClose={handleCloseConfig}
          onSave={handleSaveConfig}
          api={api}
        />
      )}
    </Card>
  )
}