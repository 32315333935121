import { useState, useEffect } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Sun, LogOut, Calendar } from 'lucide-react'
import { User, Settings, Menu, X } from 'lucide-react'
import useAxios from '../../hooks/useAxios'
import { supabase } from '../../utils/supabaseClient'


const LogoutButton = () => {
  const navigate = useNavigate()

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut()
      if (error) throw error
      toast.success('Logged out successfully')
      navigate('/signin')
    } catch (error) {
      console.error('Error logging out:', error)
      toast.error('Error logging out')
    }
  }

  return (
    <button
      onClick={handleLogout}
      className="p-2 hover:bg-accent rounded-md"
    >
      <LogOut className="h-5 w-5" />
    </button>
  )
}

const DashboardLayout = ({ darkMode, setDarkMode, outletContext }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const navItems = [
    { id: 'routine', label: 'My Routine', icon: Calendar, path: '/dashboard/routine' },
    { id: 'profile', label: 'Profile', icon: User, path: '/dashboard/profile' },
    { id: 'integrations', label: 'Integrations', icon: Settings, path: '/dashboard/integrations' },
  ];

  const currentSection = location.pathname.split('/').pop();

  return (
    <div className="min-h-screen bg-background">
      {/* Mobile Nav Header */}
      <div className="lg:hidden flex items-center justify-between p-4 border-b">
        <button
          onClick={() => setSidebarOpen(!sidebarOpen)}
          className="p-2 hover:bg-accent rounded-md"
        >
          {sidebarOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
        </button>
        <h1 className="text-xl font-bold">DayRiser</h1>
        <div className="flex items-center gap-2">
          <button onClick={() => setDarkMode(!darkMode)} className="p-2 hover:bg-accent rounded-md">
            <Sun className="h-5 w-5" />
          </button>
          <button className="p-2 hover:bg-accent rounded-md">
            <LogOut className="h-5 w-5" />
          </button>
        </div>
      </div>

      <div className="flex h-[calc(100vh-64px)] lg:h-screen">
        {/* Sidebar */}
        <aside className={`
          ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}
          fixed lg:static lg:translate-x-0
          z-20 lg:z-0
          w-64 h-full
          bg-card border-r
          transition-transform duration-200
          flex flex-col
        `}>
          {/* Desktop Logo */}
          <div className="hidden lg:flex items-center gap-3 p-6 border-b">
            <Sun className="h-6 w-6 text-yellow-500"/>
            <h1 className="text-xl font-bold">DayRiser</h1>
          </div>

          {/* Nav Items */}
          <nav className="flex-1 p-4">
            <ul className="space-y-2">
              {navItems.map(({id, label, icon: Icon, path}) => (
                <li key={id}>
                  <button
                    onClick={() => navigate(path)}
                    className={`
                      w-full px-4 py-2.5 
                      flex items-center gap-3 
                      rounded-md
                      transition-colors
                      ${currentSection === id ?
                      'bg-primary text-primary-foreground' :
                      'hover:bg-accent hover:text-accent-foreground'
                    }
                    `}
                  >
                    <Icon className="h-5 w-5"/>
                    {label}
                  </button>
                </li>
              ))}
            </ul>
          </nav>

          {/* Desktop Footer Controls */}
          <div className="hidden lg:block border-t p-4">
            <div className="flex items-center justify-between">
              <button
                onClick={() => setDarkMode(!darkMode)}
                className="p-2 hover:bg-accent rounded-md"
              >
                <Sun className="h-5 w-5"/>
              </button>
              <LogoutButton/>
            </div>
          </div>
        </aside>

        {/* Main Content */}
        <main className="flex-1 overflow-auto p-6">
          <div className="max-w-5xl mx-auto">
            <Outlet context={outletContext}/>
          </div>
        </main>

        {/* Mobile Sidebar Backdrop */}
        {sidebarOpen && (
          <div
            className="fixed inset-0 bg-black/50 lg:hidden z-10"
            onClick={() => setSidebarOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

// Main Dashboard Component
const AdminDashboard = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [profile, setProfile] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    zip_code: '',
    timezone: '',
  })
  const [availableBlocks, setAvailableBlocks] = useState([])
  const [integrations, setIntegrations] = useState([]);
  const [userBlocks, setUserBlocks] = useState([])
  const [loading, setLoading] = useState(true)
  const [schedule, setSchedule] = useState(null)
  const navigate = useNavigate()
  const location = useLocation()

  const api = {
    profile: useAxios({
      url: '/api/profile/',
      method: 'GET',
      showToast: false,
    }),
    updateProfile: useAxios({
      url: '/api/profile/',
      method: 'PATCH',
    }),
    available: useAxios({
      url: '/api/call-blocks/available/',
      method: 'GET',
      showToast: false,
    }),
    user: useAxios({
      url: '/api/call-blocks/user/',
      method: 'GET',
      showToast: false,
    }),
    add: useAxios({
      url: '/api/call-blocks/user/',
      method: 'POST',
    }),
    delete: useAxios({
      method: 'DELETE',
    }),
    update: useAxios({
      method: 'PATCH',
    }),
    schedule: useAxios({
      url: '/api/call-blocks/schedule/',
      method: 'GET',
      showToast: false,
    }),
    updateSchedule: useAxios({
      url: '/api/call-blocks/schedule/',
      method: 'PUT',
    }),
    config: useAxios({
      method: 'GET',
      showToast: false,
    }),
    integration: useAxios({
      method: 'GET',
      showToast: false,
    }),
    integrations: useAxios({
      url: '/api/integrations/',
      method: 'GET',
      showToast: false,
    }),
    connectIntegration: useAxios({
      method: 'POST',
    }),
    disconnectIntegration: useAxios({
      method: 'DELETE',
    }),
    testCall: useAxios({
      url: '/api/call-blocks/test-call/',
      method: 'POST',
      showToast: true,
    }),
  };

  const fetchIntegrations = async () => {
    try {
      const integrationsResponse = await api.integrations.fetchData();
      if (integrationsResponse) {
        setIntegrations(integrationsResponse);
      }
    } catch (error) {
      console.error('Failed to fetch integrations:', error);
    }
  };

  useEffect(() => {
    if (location.pathname === '/dashboard') {
      navigate('/dashboard/profile');
    }
  }, [location, navigate]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [
          profileResponse,
          availableResponse,
          userResponse,
          scheduleResponse,
          integrationsResponse,
        ] = await Promise.all([
          api.profile.fetchData(),
          api.available.fetchData(),
          api.user.fetchData(),
          api.schedule.fetchData(),
          api.integrations.fetchData(),
        ]);

        if (integrationsResponse) {
          setIntegrations(integrationsResponse);
        }

        if (profileResponse) {
          setProfile(profileResponse)
        }

        if (availableResponse) {
          setAvailableBlocks(availableResponse)
        }

        if (userResponse) {
          const sortedBlocks = userResponse
            .sort((a, b) => a.order - b.order)
            .map((block) => ({
              ...block.block_details,
              id: block.id,
              settings: block.settings,
              order: block.order,
              is_enabled: block.is_enabled,
            }))
          setUserBlocks(sortedBlocks)
        }

        if (scheduleResponse) {
          setSchedule(scheduleResponse)
        }
      } catch (error) {
        console.error('Error loading data:', error)
        toast.error('Failed to load data. Please try again.')
      } finally {
        setLoading(false)
      }
    }
    loadData()
  }, [])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('integration') === 'success') {
      // Clear the URL parameter
      window.history.replaceState({}, '', window.location.pathname);
      // Refresh integrations
      fetchIntegrations();
      toast.success('Calendar connected successfully!');
    }
  }, []);

  const handleScheduleSave = async (newSchedule) => {
    try {
      const response = await api.updateSchedule.fetchData({
        data: newSchedule,
      })
      if (response) {
        setSchedule(response)
        toast.success('Schedule updated successfully!')
      }
    } catch (error) {
      console.error('Failed to update schedule:', error)
      toast.error('Failed to update schedule. Please try again.')
    }
  }

  const handleProfileChange = (e) => {
    const { name, value } = e.target
    setProfile((prev) => ({ ...prev, [name]: value }))
  }

  const handleProfileSubmit = async (e) => {
    e.preventDefault()
    try {
      const response = await api.updateProfile.fetchData({
        data: profile,
      });

      // Only update the profile and show success if no verification is needed
      if (!response.requires_email_verification && !response.requires_phone_verification) {
        setProfile(response);
        toast.success('Profile updated successfully!');
      }

      return response; // Return the response so ProfileSection can handle verification flows
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Failed to update profile. Please try again.');
      throw error; // Re-throw to let ProfileSection handle the error
    }
  };

  const handleAddBlock = async (block) => {
    try {
      // Check if block requires integration
      if (block.requires_integration) {
        const hasRequiredIntegration = integrations?.some(
          i => i.provider === block.integration_type && i.is_active
        );

        if (!hasRequiredIntegration) {
          toast.error(
            `This block requires ${block.integration_type} integration. Please connect first.`
          );
          return;
        }
      }

      const response = await api.add.fetchData({
        data: {
          block: block.id,
          settings: block.default_settings || {},
          order: userBlocks.length,
        },
      });

      if (response) {
        const newBlock = {
          ...block,
          id: response.id,
          settings: response.settings,
          order: response.order,
          is_enabled: response.is_enabled,
        };
        setUserBlocks((prev) => [...prev, newBlock]);
        toast.success(`Added ${block.name} to your routine`);
      }
    } catch (error) {
      console.error('Failed to add block:', error);
      toast.error('Failed to add block. Please try again.');
    }
  };
  const handleSaveBlockConfig = async (blockId, newSettings) => {
    try {
      await api.update.fetchData({
        url: `/api/call-blocks/user/${blockId}/`,
        data: { settings: newSettings },
      })
      setUserBlocks((prev) =>
        prev.map((block) =>
          block.id === blockId ? { ...block, settings: newSettings } : block
        )
      )
      toast.success('Block settings updated successfully')
    } catch (error) {
      console.error('Failed to update block settings:', error)
      toast.error('Failed to update block settings. Please try again.')
    }
  }

  const handleConnectIntegration = async (provider) => {
    try {
      const response = await api.connectIntegration.fetchData({
        url: `/api/integrations/${provider}/connect/`,
      });

      if (response?.authorization_url) {
        window.location.href = response.authorization_url;
      }
    } catch (error) {
      console.error('Failed to initiate integration:', error);
      toast.error('Failed to start integration process. Please try again.');
    }
  };

  const handleDisconnectIntegration = async (provider) => {
    try {
      await api.disconnectIntegration.fetchData({
        url: `/api/integrations/${provider}/`,
      });

      setIntegrations(prev =>
        prev.map(integration =>
          integration.provider === provider
            ? { ...integration, is_active: false }
            : integration
        )
      );

      toast.success('Integration disconnected successfully');
    } catch (error) {
      console.error('Failed to disconnect integration:', error);
      toast.error('Failed to disconnect integration. Please try again.');
    }
  };

  const handleTestCall = async () => {
    try {
      await api.testCall.fetchData();
      toast.success('Test call initiated! You should receive a call shortly.');
    } catch (error) {
      console.error('Failed to initiate test call:', error);
      toast.error('Failed to initiate test call. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-50 dark:bg-gray-900">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    )
  }

  return (
    <div className={darkMode ? 'dark' : ''}>
      <DashboardLayout
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        outletContext={{
          profile,
          handleAddBlock,
          handleProfileChange,
          handleProfileSubmit,
          handleSaveBlockConfig,
          integrations,
          handleConnectIntegration,
          handleDisconnectIntegration,
          availableBlocks,
          userBlocks,
          setUserBlocks,
          schedule,
          handleScheduleSave,
          handleTestCall,
          api
        }}
      />
    </div>
  );
};

export default AdminDashboard;