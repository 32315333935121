import React from 'react'
import { AlertCircle, Check, Plus, Calendar } from "lucide-react"
import { Button } from "@/components/ui/button"
import { Card, CardContent } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"

const BlockCard = ({ block, onAdd, integrations = [], onConnectIntegration }) => {
  const isIntegrationRequired = block.requires_integration
  const requiredIntegration = block.integration_type
  const hasIntegration = integrations?.some(
    (i) => i.provider === requiredIntegration && i.is_active
  )

  return (
    <Card className="group relative overflow-hidden transition-all hover:shadow-lg">
      <CardContent className="p-6">
        <div className="mb-4 flex items-center justify-between">
          <span className="inline-block text-3xl">{block.icon || '🔲'}</span>
          {isIntegrationRequired && (
            <Badge
              variant={hasIntegration ? "success" : "secondary"}
              className="h-6 whitespace-nowrap"
            >
              {hasIntegration ? (
                <span className="flex items-center gap-1">
                  <Check className="h-3 w-3" />
                  Connected
                </span>
              ) : (
                <span className="flex items-center gap-1">
                  <Calendar className="h-3 w-3" />
                  Needs Calendar
                </span>
              )}
            </Badge>
          )}
        </div>

        <div className="space-y-2 min-h-[4rem]">
          <h3 className="font-semibold tracking-tight">{block.name}</h3>
          <p className="text-sm text-muted-foreground line-clamp-2">{block.description}</p>
        </div>

        <div className="mt-4">
          {isIntegrationRequired && !hasIntegration ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => onConnectIntegration(requiredIntegration)}
              className="w-full shadow-sm"
            >
              Connect Calendar
            </Button>
          ) : (
            <Button
              size="sm"
              onClick={() => onAdd(block)}
              className="w-full shadow-sm"
            >
              <Plus className="mr-2 h-4 w-4" />
              Add to Routine
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export default BlockCard